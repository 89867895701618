import { Component, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './profile.page.labels';

@Component({
	selector: 'profile-page',
	standalone: true,
	imports: [
		MobilePagePart,
		MobileListWidget,
	],
	templateUrl: './profile.page.html'
})

export class ProfilePage {
	private frame = inject(MobileFrameService);
	private router = inject(Router);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	public primaryList = computed<MobileListWithSubItem[]>(() => [
		{ icon: 'fa-id-card', iconStyle: 'fa-regular', text: this.labels.BasicInfo(), callback: () => this.navigateTo('/profile/info'), subText: this.labels.BasicInfoSubtext() },
		{ icon: 'fa-dna', iconStyle: 'fa-regular', text: this.labels.Demographics(), callback: () => this.navigateTo('/profile/demographics'), subText: this.labels.DemographicsSubtext() },
		{ icon: 'fa-envelope', iconStyle: 'fa-regular', text: this.labels.Emails(), callback: () => this.navigateTo('/profile/emails') },
		{ icon: 'fa-link', iconStyle: 'fa-regular', text: this.labels.SocialLinks(), callback: () => this.navigateTo('/profile/social-links') },
	]);

	public secondaryList = computed<MobileListWithSubItem[]>(() => [
		{ icon: 'fa-file-contract', iconStyle: 'fa-regular', text: this.labels.common.SignedAgreements(), callback: () => this.navigateTo('/agreements') },
		{ icon: 'fa-gear', iconStyle: 'fa-regular', text: this.labels.NotificationSettings(), callback: () => this.navigateTo('/profile') },
		{ icon: 'fa-language', iconStyle: 'fa-regular', text: this.labels.AppLanguage(), callback: () => this.navigateTo('/profile') },
	]);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile',
			backUrl: '/',
			pageName: computed(() => this.labels.common.Profile()),
			headerText: computed(() => this.labels.common.Profile()),
		});
	}

	navigateTo(path: string) {
		this.router.navigate([this.urlService.withCurrentLanguage(path)]);
	}

}
<mobile-page-part>

	<div class="info-content">
		@if(courseService.courses()?.length){<h3>Accelerator Course</h3>}
		@else if(topics().length){<h3>{{util.text.singularOrPluralWithCount(topics().length, 'Topic Available',
			'Topics Available')}}</h3>}
		@else {<h3>No Ongoing Session</h3>}

	</div>



	@if(courseService.courses()?.length){

	@for(card of courseCardAndList().cards; track $index){

	<div class="white-box">
		<h4>{{card.header}}</h4>
		<div class="d-flex flex-column gap-3">
			<markdown [data]="card.infoMarkdown"></markdown>

			@if(card.percentComplete !== undefined){
			<div>{{card.activityCompleteCountMsg}}</div>
			<common-progress-bar [percentage]="card.percentComplete"></common-progress-bar>
			}

			@if(card.dueDate){
			<div>Due: {{card.dueDate}}</div>
			}

		</div>
		<common-button-row [center]="true">
			<button class="btn btn-primary" routerLink="{{card.navigateUrl}}">{{card.buttonText}}</button>
			@if(card.overviewUrl){
			<button class="btn btn-standard" routerLink="{{card.overviewUrl}}">Course Overview</button>
			}
		</common-button-row>

	</div>

	}


	@if(courseCardAndList().dueList.length){
	<mobile-list [items]="courseCardAndList().dueList" header="Due Soon"></mobile-list>
	}


	<!-- <common-button-row [center]="true">
		<button class='btn btn-standard btn-large' routerLink="courses/1">Course Overview</button>
	</common-button-row> -->

	}
	@else if(topics().length) {
	@if(topics(); as topics){
	<div ngbAccordion [closeOthers]="true">
		@for (topic of topics; track $index;) {
		<div ngbAccordionItem>
			<div ngbAccordionHeader>
				<button ngbAccordionButton class="btn btn-flat p-2">
					{{topic.topicName}}
				</button>
			</div>

			<div ngbAccordionCollapse>
				<div ngbAccordionBody class="p-2">
					<ng-template>
						<p>{{topic.topicGoals}}</p>


						<h4>Materials:</h4>

						@if(!topic.hasMaterials){
						<i class="color-gray">
							{{labels.TopicNoMaterialsMsg()}}
						</i>
						}


						@if(topic.topicMaterials.length){

						<table class="table table-sm table-bordered">
							@for(material of topic.topicMaterials; track material.id;){
							<tr>
								<td class="p-1">{{material.id}}:</td>
								<td class="p-1"><i class="fa-fw {{material.icon}}"></i></td>
								<td class="p-1"><a [href]="material.url" target="_blank">
										{{material.name}}
									</a>
								</td>
							</tr>
							}
						</table>
						}
					</ng-template>
				</div>
			</div>
		</div>
		}

	</div>
	}
	}
	@else {
	<div>No Ongoing Sessions. TBD</div>
	}

</mobile-page-part>
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonIconTextWidget } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { getLabels } from './submit-flyout.part.labels';
import { ApplicationService } from '../../application.service';

@Component({
	selector: 'application-submit-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
	],
	templateUrl: './submit-flyout.part.html',
	styleUrl: './submit-flyout.part.scss'
})
export class ApplicationSubmitFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	public labels = getLabels(this.applicationService.urlService);


	close() {
		this.activeOffcanvas.close();
	}



	public async submit() {
		await this.applicationService.submit();
		this.close();
	}

}
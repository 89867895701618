<div class="d-flex flex-column gap-3">
	<markdown class="white-box" [data]="infoTextMarkdown()"></markdown>

	@if(!applyService.selectedApplicationProgramInstance())
	{
		@if(!applyService.continueClicked())
		{
		<div>
			<h4>{{labels.ConfirmHomeAddress()}}</h4>
			<common-address-field [field]="applyService.form.homeAddress()"></common-address-field>
		</div>

		<div class="d-flex flex-column gap-2">
			<h4>{{labels.WhereWillYourBusinessBeLocated()}}</h4>
			<common-select-field [field]="applyService.form.businessLocation()"></common-select-field>

			@if(applyService.form.businessLocation().pendingValue() === 'Y'){
			<common-address-field [field]="applyService.form.businessAddress()"></common-address-field>
			}
		</div>

		<common-button-row [center]="true">
			<button class="btn btn-primary btn-large" [disabled]="!applyService.canContinue()"
				(click)="applyService.continue()">{{labels.button.Continue()}}</button>
		</common-button-row>
		}

		<application-programs-part></application-programs-part>
	}
	@else {
	<application-company-part></application-company-part>

	}
</div>
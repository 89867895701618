import { UrlService } from '@eforall/common';
import { computed } from '@angular/core';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Agreements: computed(() => en() ? `Agreements.` : `{{ ES: Agreements. }}`),
		NoAgreements: computed(() => en() ? `No agreements.` : `{{ ES: No agreements. }}`),
		SignatureRequired: computed(() => en() ? `Signature Required` : `{{ ES: Signature Required }}`),
		SignedAgreement: computed(() => en() ? `Signed Agreement` : `{{ ES: Signed Agreement }}`),
		SignedAgreements: computed(() => en() ? `Signed Agreements` : `{{ ES: Signed Agreements }}`),
		SignedOn: computed(() => en() ? `Signed on` : `{{ ES: Signed on }}`),
		UnsignedAgreement: computed(() => en() ? `Unsigned Agreement` : `{{ ES: Unsigned Agreement }}`),
		UnsignedAgreements: computed(() => en() ? `Unsigned Agreements` : `{{ ES: Unsigned Agreements }}`),
		UpdatedOn: computed(() => en() ? `Updated on` : `{{ ES: Updated on }}`),
	};
}
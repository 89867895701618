@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" [text]="labels.ConfirmSubmission()" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		@if(applicationService.application(); as application)
		{
		@if(application.application.applicationStatusId > 3)
		{
		<div>
			{{labels.PostSubmitThankYouMsg()}}
		</div>
		}
		@else if(applicationService.stepsCompleted() !== 4)
		{
		<div>
			{{labels.CompleteAllBeforeSubmitMsg()}}
		</div>
		}
		@else if(applicationService.stepsCompleted() === 4 && application.canSubmit)
		{
		<div>
			{{labels.CanSubmitApplication()}}
			<button class="btn btn-primary" (click)="applicationService.submit()">{{labels.button.Submit()}}</button>
		</div>
		}

		}
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}
import { Component, OnInit, inject, input } from '@angular/core';
import { CommonAddressFieldWidget, CommonButtonRowWidget, CommonSelectFieldWidget } from '@eforall/common';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { getLabels } from './apply.part.labels';
import { ApplicationApplyService } from './apply.service';
import { ApplicationCompanyPart } from './company-part/company.part';
import { ApplicationProgramsPart } from './programs-part/programs.part';

@Component({
	selector: 'application-apply-part',
	standalone: true,
	imports: [
		ApplicationCompanyPart,
		ApplicationProgramsPart,
		CommonAddressFieldWidget,
		CommonButtonRowWidget,
		CommonSelectFieldWidget,
		MarkdownModule,
	],
	providers: [provideMarkdown()],
	templateUrl: './apply.part.html'
})

export class ApplicationApplyPart implements OnInit {
	public applyService = inject(ApplicationApplyService);

	public infoTextMarkdown = input.required<string>();
	public type = input.required<'Acc' | 'Pic'>();
	public labels = getLabels(this.applyService.urlService);

	ngOnInit(): void {
		this.applyService.onInit(this.type());
	}


}
<mobile-page-part>

	<div class="info-content">
		<h3>{{labels.JoinAnApplication()}}</h3>
	</div>

	@if(codeDetails(); as codeDetails){
	<div class="white-box d-flex flex-column gap-3">
		<h3>{{labels.InvitedToJoin()}}:</h3>
		<div>
			<div>{{labels.Program()}}: {{codeDetails.programName}}</div>
			<div>{{labels.Business()}}: {{codeDetails.companyName}}</div>
			<div>{{labels.Applicant()}}: {{codeDetails.applicantName}}</div>
		</div>

		@if(!codeDetails.applicationOpen){
		<div class="color-darkred">{{labels.UnableToJoin()}}</div>
		}
		@else if(!join){
		<common-button-row [center]="true">
			<button class="btn btn-primary" (click)="join = true">{{labels.Join()}}</button>
			<button class="btn btn-standard" [routerLink]="form.urlService.withCurrentLanguage('/')">{{labels.NotMe()}}</button>
		</common-button-row>
		}
	</div>

	@if(join && codeDetails.applicationOpen){

	<div class="white-box d-flex flex-column gap-3">
		<h3>{{labels.TellUsAboutYou()}}</h3>
		<common-button-column>
			<button class="btn btn-primary btn-large"
				(click)="form.openInstructionFlyout()">{{labels.Instructions()}}</button>
		</common-button-column>

		<common-select-field [field]="form.doingItFullTime()"></common-select-field>
		<common-text-field [field]="form.title()"></common-text-field>
		<common-text-field [field]="form.firstName()"></common-text-field>
		<common-text-field [field]="form.middleInit()"></common-text-field>
		<common-text-field [field]="form.lastName()"></common-text-field>
		<!-- Gender -->
		<common-select-field [field]="form.gender()"></common-select-field>
		@if(showOtherGender()){
		<common-text-field [field]="form.genderOther()"></common-text-field>
		}



		<!-- Hispanic or Latino -->
		<common-select-field [field]="form.hispanicOrLatino()"></common-select-field>


		<!-- Races -->
		<common-multiselect-field [field]="form.races()"></common-multiselect-field>


		<!-- Veteran -->
		<common-select-field [field]="form.veteran()"></common-select-field>



		<!-- Immigrant -->
		<common-select-field [field]="form.immigrant()"></common-select-field>


		<!-- Native English Speaker -->
		<common-select-field [field]="form.nativeEnglish()"></common-select-field>



		<!-- Native Spanish Speaker -->
		<common-select-field [field]="form.nativeSpanish()"></common-select-field>


		<!-- Birthday -->
		<common-date-time-field [field]="form.birthday()"></common-date-time-field>


		<common-select-field [field]="form.employmentStatus()"></common-select-field>
		<common-select-field [field]="form.headOfHousehold()"></common-select-field>
		<common-number-field [field]="form.householdIncome()"></common-number-field>

		<common-phone-field [field]="form.phone()"></common-phone-field>
		<common-address-field [field]="form.address()"></common-address-field>


		<common-button-column>
			<button class="btn btn-primary btn-large" (click)="form.joinApplication(code())">{{labels.JoinApplication()}}</button>
		</common-button-column>
	</div>
	}

	}
	@else{
	<div class="color-darkred">{{labels.CodeAlreadyUsed().split('{code}').join(code())}}</div>
	}


</mobile-page-part>
import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ConfirmSubmission: computed(() => en() ? `Confirm Submission` : `{{ ES: Confirm Submission }}`),
		CanSubmitApplication: computed(() => en() ? `TODO: Message will be added soon.\n\nCan submit application` : `{{ ES: TODO: Message will be added soon.\n\nCan submit application }}`),
		PostSubmitThankYouMsg: computed(() => en() ? `TODO: Message will be added soon.\n\nThank you for submitting your application etc etc` : `{{ ES: TODO: Message will be added soon.\n\nThank you for submitting your application etc etc }}`),
		CompleteAllBeforeSubmitMsg: computed(() => en() ? `TODO: Message will be added soon.\n\nDisplay message - Cannot submit. Please complete all the steps before submitting.` : `{{ ES: TODO: Message will be added soon.\n\nDisplay message - Cannot submit. Please complete all the steps before submitting. }}`),

		
	}
}
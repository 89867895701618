import { Injectable, computed, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { UserApplication } from "@app-interfaces";
import { UrlService, UtilityService } from "@eforall/common";
import { Answer, ApplicationStatusId, Participant } from '@interfaces';
import { AppService, FlyoutService, FuncService } from '../../../services';
import { getLabels } from "./application.page.labels";

@Injectable({ providedIn: 'root' })
export class ApplicationService {
	public app = inject(AppService);
	public util = inject(UtilityService);
	public flyoutService = inject(FlyoutService);
	private func = inject(FuncService);
	public router = inject(Router);
	public urlService = inject(UrlService);
	public labels = getLabels(this.urlService);


	public applicationId = signal<number | undefined>(undefined);
	public answers = signal<Answer[]>([]);

	public application = computed<UserApplication | undefined>(() => {
		const application = this.app.data().userApplications.find(appl => appl.application.applicationId == this.applicationId());
		if (application) {
			return application;
		}
		else return undefined;
	},
		{ equal: this.util.values.areSame },
	);

	public instructionInfo = computed<string>(() => this.application()?.application.accId ?
		this.labels.InstructionsInfoMsg() : this.application()?.application.picId ? '' : '');



	public selectedQuestion = signal<Answer | undefined>(undefined);

	public aboutBusinessComplete = computed<boolean>(() => {
		const application = this.application();
		if (application) {
			return !!application.application.companyName
				&& !!application.application.companyTypeId
				&& !!application.business.industryId
				&& !!application.business.offerings
				&& application.application.annualRevenue != undefined
				&& application.application.numFullTimeEmployees != undefined
				&& application.application.numPartTimeEmployees != undefined
				&& application.application.numContractors != undefined
				&& !!application.business.phone;
		}
		else return false;
	});

	public aboutYouComplete = computed<boolean>(() => {
		const application = this.application();
		const user = this.app.data().user;
		const participant = application?.participants.find(participant => participant.personId == user.personId);

		if (application && participant) {

			return !!participant.doingItFullTime
				&& !!participant.employmentStatusId
				&& !!participant.headOfHousehold
				&& !!participant.title
				&& !!participant.homeZipId
				&& !!participant.placeId
				&& participant.householdIncome != undefined
				&& !!participant.firstName
				&& !!participant.lastName
				&& !!participant.birthDay && !!participant.birthYear
				&& !!participant.gender
				&& !!participant.races?.length
				&& !!participant.hispanic
				&& !!participant.immigrant
				&& !!participant.veteran
				&& !!participant.nativeEnglish
				&& !!participant.nativeSpanish
				&& !!participant.phone


		}
		else return false;

	});



	public aboutTeamComplete = computed<boolean>(() => {
		const application = this.application();
		if (application) {
			for (const participant of application.participants) {

				const complete = !!participant.doingItFullTime
					&& !!participant.employmentStatusId
					&& !!participant.headOfHousehold
					&& !!participant.title
					&& !!participant.homeZipId
					&& !!participant.placeId
					&& participant.householdIncome != undefined
					&& !!participant.firstName
					&& !!participant.lastName
					&& !!participant.birthDay && !!participant.birthYear
					&& !!participant.gender
					&& !!participant.races
					&& !!participant.hispanic
					&& !!participant.immigrant
					&& !!participant.veteran
					&& !!participant.nativeEnglish
					&& !!participant.nativeSpanish
					&& !!participant.phone;

				if (!complete) return false;
			}


			if (!application.application.restOfTeam) return false;
		}
		return true;
	});

	public answersComplete = computed<boolean>(() => {
		const answers = this.answers().filter(answer => answer.question.isRequired);

		return !answers.find(answer => !answer.answer);
	});


	public stepsCompleted = computed(() => {
		let count = 0;
		if (this.aboutBusinessComplete()) count++;
		if (this.aboutYouComplete()) count++;
		if (this.aboutTeamComplete()) count++;
		if (this.answersComplete()) count++;
		return count;
	});


	public invitedParticipants = computed<Participant[]>(() => {
		const application = this.application();
		const participants: Participant[] = [];
		if (application) {
			const filteredParticipants: Participant[] = application.participants
				.filter(participants => participants.personId !== application.application.personId)
				.reduce((a: Participant[], p) => {
					a.push({
						applicationParticipantId: p.applicationParticipantId,
						applicationParticipantPendingId: undefined,
						email: p.email,
						title: p.title,
						firstName: p.firstName,
						lastName: p.lastName,
						status: p.isCompleted ? this.labels.Complete() : this.labels.Incomplete(),
					});
					return a;
				}, []);

			const pendingParticipants: Participant[] = application.pendingParticipants
				.reduce((a: Participant[], p) => {
					a.push({
						applicationParticipantPendingId: p.applicationParticipantPendingId,
						applicationParticipantId: undefined,
						email: p.email,
						title: p.title,
						firstName: p.firstName,
						lastName: p.lastName,
						status: this.labels.Pending(),
						code: p.code,
					});
					return a;
				}, []);

			participants.push(...filteredParticipants, ...pendingParticipants);
		}

		return participants;

	});



	navigateTo(params: { step: string, questionId?: number, }) {
		if (!params.step && params.questionId == undefined) console.error(`Cannot navigate as no step or questionId was provided.`)

		else if (params.questionId != undefined) {
			const answer = this.answers().find(answer => answer.question.questionId == params.questionId);
			this.selectedQuestion.set(answer ?? this.answers()[0]);

			const path = `/applications/${this.applicationId()}/questions/${this.selectedQuestion()?.question.questionId}`;
			this.router.navigate(
				[this.urlService.withCurrentLanguage(path)],
			);
		}
		else {
			const path = `/applications/${this.applicationId()}/${params.step}`;
			this.router.navigate(
				[this.urlService.withCurrentLanguage(path)],
			);
		}

		this.scrollTop();
	}

	setSelectedQuestionAndNavigate(index: number) {
		const answer = this.answers()[index];
		this.selectedQuestion.set(answer);
		if (answer) {
			const path = `/applications/${this.applicationId()}/questions/${answer.question.questionId}`;
			this.router.navigate(
				[this.urlService.withCurrentLanguage(path)],
			);
		}

	}

	navigateToApplication() {
		const path = `/applications/${this.applicationId()}`;
		this.router.navigate(
			[this.urlService.withCurrentLanguage(path)],
		);
	}


	async setApplicationIdAndQuestion(applicationId: number, questionId?: number) {
		if (!applicationId) {
			console.error(`No application id found.`);
			return;
		}

		if (this.applicationId() != applicationId) {
			this.applicationId.set(applicationId);
			const answers = await this.func.application.getAnswers({ applicationId });
			this.answers.set(answers);
		}

		if (questionId && this.selectedQuestion()?.question.questionId != questionId) {
			const selectedQuestion = this.answers().find(answer => answer.question.questionId == questionId);
			// if (!selectedQuestion) this.router.navigate([`/error`],);
			this.selectedQuestion.set(selectedQuestion);
		}
	}


	public openInstructionFlyout(instruction: string) {
		this.flyoutService.showMarkdownMessage(instruction, this.labels.Instructions());
	}

	public openMakeChangesFlyout() {
		const application = this.application();

		if (application && (application.canWithdraw || application.canReopen)) {
			this.flyoutService.showApplicationMakeChanges();
		}
	}


	public async removeParticipant(participant: Participant) {
		const application = this.application();
		if (application) {

			if (participant.applicationParticipantId) {
				await this.app.update(this.func.participant.remove, { applicationId: application.application.applicationId, applicationParticipantId: participant.applicationParticipantId });
			}
			else if (participant.applicationParticipantPendingId) {
				await this.app.update(this.func.pendingParticipant.remove, { applicationId: application.application.applicationId, applicationParticipantPendingId: participant.applicationParticipantPendingId });
			}
		}
	}

	public async resendInvite(participant: Participant) {
		const application = this.application();
		if (application && participant.code) {
			await this.func.pendingParticipant.resendInvite({ applicationId: application.application.applicationId, firstName: participant.firstName, code: participant.code, email: participant.email });
		}
	}


	private scrollTop() {
		const pageElement = document.querySelector('.page-content');
		if (pageElement) {
			pageElement.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}


	public async submit() {
		const application = this.application();
		if (application && this.stepsCompleted() === 4 && application.canSubmit) {
			await this.app.update(this.func.application.submit, { applicationId: application.application.applicationId });
		}

		this.scrollTop();
	}

	public async reopen() {
		const application = this.application();
		if (application && application.canReopen) {
			await this.app.update(this.func.application.withdraw, { applicationId: application.application.applicationId, applicationStatusId: ApplicationStatusId.AcceptPending });
		}
		this.scrollTop();
	}

	public async withdraw() {
		const application = this.application();
		if (application && application.canWithdraw) {
			await this.app.update(this.func.application.withdraw, { applicationId: application.application.applicationId, applicationStatusId: ApplicationStatusId.AcceptWithdrawn });
		}
		this.scrollTop();
	}


	public getInstructionKey(key: string): boolean {
		return sessionStorage.getItem(key) ? sessionStorage.getItem(key) === 'clicked' : false;
	}

	public setInstructionKey(key: string, value: 'clicked') {
		sessionStorage.setItem(key, value);
	}

}
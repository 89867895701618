import { computed, Signal } from '@angular/core';


export function getCommonLabels(isEnglish: Signal<boolean>) {

	const en = isEnglish;

	return {

		/** Common EforAll terms */
		common: {
			Applications: computed(() => en() ? `Applications` : `Applications`),
			Calendar: computed(() => en() ? `Calendar` : `Calendario`),
			Cohort: computed(() => en() ? `Cohort` : `Cohorte`),
			Cohorts: computed(() => en() ? `Cohorts` : `Cohortes`),
			Contact: computed(() => en() ? `Contact` : `Contacto`),
			Contacts: computed(() => en() ? `Contacts` : `Contactos`),
			Demographics: computed(() => en() ? `Demographics` : `Demographics`),
			EforAll: computed(() => en() ? `EforAll` : `EparaTodos`),
			EforAllWebsite: computed(() => en() ? `eforall.org` : `eparatodos.org`),
			Feedback: computed(() => en() ? `Feedback` : `Comentario`),
			Goals: computed(() => en() ? `Goals` : `Objetivos`),
			Home: computed(() => en() ? `Home` : `Inicio`),
			Learning: computed(() => en() ? `Learning` : `Aprendiendo`),
			MyTeam: computed(() => en() ? `My Team` : `Mi equipo`),
			NotAuthorized: computed(() => en() ? `Not Authorized` : `No autorizado`),
			Profile: computed(() => en() ? `Profile` : `Perfil`),
			Programs: computed(() => en() ? `Programs` : `Programas`),
			ProgramManager: computed(() => en() ? `Program Manager` : `Gerente de Programa`),
			SignedAgreements: computed(() => en() ? `Signed Agreements` : `Documentos Firmados`),
			Specialist: computed(() => en() ? `Specialist` : `Especialista`),
			Surveys: computed(() => en() ? `Surveys` : `Encuestas`),
			Topic: computed(() => en() ? `Topic` : `Tema`),
			Venture: computed(() => en() ? `Venture` : `Empresa`),
			Ventures: computed(() => en() ? `Ventures` : `Empresas`),
			Volunteer: computed(() => en() ? `Volunteer` : `Voluntario`),
		},


		employmentStatusOptions: {
			Employed: computed(() => en() ? `Employed` : `{{ ES: Employed }}`),
			Retired: computed(() => en() ? `Retired` : `{{ ES: Retired }}`),
			Underemployed: computed(() => en() ? `Underemployed` : `{{ ES: Underemployed }}`),
			Unemployed: computed(() => en() ? `Unemployed` : `{{ ES: Unemployed }}`),
		},

		/** Standard labels used in forms */
		form: {
			Address: computed(() => en() ? `Address` : `{{ ES: Address }}`),
			Answer: computed(() => en() ? `Answer` : `{{ ES: Answer }}`),
			Birthday: computed(() => en() ? `Birthday` : `{{ ES: Birthday }}`),
			Email: computed(() => en() ? `Email` : `{{ ES: Email }}`),
			EmployementStatus: computed(() => en() ? `Employement Status` : `{{ ES: Employement Status }}`),
			FirstName: computed(() => en() ? `First Name` : `{{ ES: First Name }}`),
			Gender: computed(() => en() ? `Gender` : `{{ ES: Gender }}`),
			HeadOfHousehold: computed(() => en() ? `Head of Household (according to IRS)` : `{{ ES: Head of Household (according to IRS) }}`),
			HispanicOrLatino: computed(() => en() ? `Hispanic Or Latino` : `{{ ES: Hispanic Or Latino }}`),
			HomeAddress: computed(() => en() ? `Home Address` : `{{ ES: Home Address }}`),
			HouseholdIncomeLastYear: computed(() => en() ? `Household Income (last year)` : `{{ ES: Household Income (last year) }}`),
			Immigrant: computed(() => en() ? `Immigrant` : `{{ ES: Immigrant }}`),
			Involvement: computed(() => en() ? `Involvement` : `{{ ES: Involvement }}`),
			LastName: computed(() => en() ? `Last Name` : `{{ ES: Last Name }}`),
			MiddleInitial: computed(() => en() ? `Middle Initial` : `{{ ES: Middle Initial }}`),
			NativeEnglishSpeaker: computed(() => en() ? `Native English Speaker` : `{{ ES: Native English Speaker }}`),
			NativeSpanishSpeaker: computed(() => en() ? `Native Spanish Speaker` : `{{ ES: Native Spanish Speaker }}`),
			OtherGender: computed(() => en() ? `Other Gender` : `{{ ES: Other Gender }}`),
			Phone: computed(() => en() ? `Phone` : `{{ ES: Phone }}`),
			PhoneNumber: computed(() => en() ? `Phone Number` : `{{ ES: Phone Number }}`),
			Question: computed(() => en() ? `Question` : `{{ ES: Question }}`),
			Race: computed(() => en() ? `Race` : `{{ ES: Race }}`),
			Save: computed(() => en() ? `Save` : `{{ ES: Save }}`),
			SelectEllipses: computed(() => en() ? `Select...` : `{{ ES: Select... }}`),
			Veteran: computed(() => en() ? `Veteran` : `{{ ES: Veteran }}`),
			YourTitle: computed(() => en() ? `Your Title` : `{{ ES: Your Title }}`),
			Title: computed(() => en() ? `Title` : `{{ ES: Title }}`),

		},

		genderOption: {
			Female: computed(() => en() ? `Female` : `{{ ES: Female }}`),
			Male: computed(() => en() ? `Male` : `{{ ES: Male }}`),
			NonBinary: computed(() => en() ? `Non-Binary` : `{{ ES: Non-Binary }}`),
			Other: computed(() => en() ? `Other` : `{{ ES: Other }}`),
		},


		headOfHouseholdOptions: {
			IAmHead: computed(() => en() ? `I am Head of Household` : `{{ ES: I am Head of Household }}`),
			IAmNotHead: computed(() => en() ? `I am NOT Head of Household` : `{{ ES: I am NOT Head of Household }}`),
		},

		hispanicOptions: {
			IAmHispanic: computed(() => en() ? `I am Hispanic or Latino` : `{{ ES: I am Hispanic or Latino }}`),
			IAmNotHispanic: computed(() => en() ? `I am NOT Hispanic or Latino` : `{{ ES: I am NOT Hispanic or Latino }}`),
		},


		immigrantOptions: {
			Immigrant: computed(() => en() ? `I immigrated to the U.S.` : `{{ ES: I immigrated to the U.S. }}`),
			NotImmigrant: computed(() => en() ? `I am NOT an immigrant to the U.S.` : `{{ ES: I am NOT an immigrant to the U.S. }}`),
		},


		nativeEnglishOptions: {
			NativeSpeaker: computed(() => en() ? `I am a Native English Speaker` : `{{ ES: I am a Native English Speaker }}`),
			NotNativeSpeaker: computed(() => en() ? `I am NOT a Native English Speaker` : `{{ ES: I am NOT a Native English Speaker }}`),
		},


		nativeSpanishOptions: {
			NativeSpeaker: computed(() => en() ? `I am a Native Spanish Speaker` : `{{ ES: I am a Native Spanish Speaker }}`),
			NotNativeSpeaker: computed(() => en() ? `I am NOT a Native Spanish Speaker` : `{{ ES: I am NOT a Native Spanish Speaker }}`),
		},


		raceOptions: {
			AfricanAmerican: computed(() => en() ? 'Black or African American' : '{{ ES: Black or African American }}'),
			AmericanIndian: computed(() => en() ? 'American Indian or Alaska Native' : '{{ ES: American Indian or Alaska Native }}'),
			Asian: computed(() => en() ? 'Asian' : '{{ ES: Asian }}'),
			PacificIslander: computed(() => en() ? 'Native Hawaiian or Other Pacific Islander' : '{{ ES: Native Hawaiian or Other Pacific Islander }}'),
			White: computed(() => en() ? 'White' : '{{ ES: White }}'),
		},


		veteranOptions: {
			IAmVeteran: computed(() => en() ? `I am a U.S. Veteran` : `{{ ES: I am a U.S. Veteran }}`),
			IAmNotVeteran: computed(() => en() ? `I am NOT a U.S. Veteran` : `{{ ES: I am NOT a U.S. Veteran }}`),
		},



		/** Standard labels used on buttons */
		button: {
			Apply: computed(() => en() ? 'Apply' : 'Aplica'),
			ApplyInLanguage: computed(() => en() ? 'Solicite en español' : 'Apply in English'),
			Cancel: computed(() => en() ? 'Cancel' : `Cancelar`),
			Continue: computed(() => en() ? 'Continue' : `{{ ES: Continue }}`),
			Close: computed(() => en() ? 'Close' : `{{ ES: Close }}`),
			LearnMore: computed(() => en() ? 'Learn More' : 'Conoce más'),
			Next: computed(() => en() ? `Next` : `{{ ES: Next }}`),
			No: computed(() => en() ? `No` : `{{ ES: No }}`),
			Okay: computed(() => en() ? `Okay` : `{{ ES: Okay }}`),
			Open: computed(() => en() ? `Open` : `{{ ES: Open }}`),
			Previous: computed(() => en() ? `Previous` : `{{ ES: Previous }}`),
			Remove: computed(() => en() ? `Remove` : `{{ ES: Remove }}`),
			Save: computed(() => en() ? `Save` : `{{ ES: Save }}`),
			Submit: computed(() => en() ? `Submit` : `{{ ES: Submit }}`),
			Yes: computed(() => en() ? `Yes` : `{{ ES: Yes }}`),
		}

	};
}
import { Component, inject } from '@angular/core';
import { CommonIconTextWidget } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from '../../application.service';
import { ApplicationReadonlyPart } from '../../readonly/application-readonly.part';
import { getLabels } from './application-readonly-flyout.part.labels';

@Component({
	selector: 'application-readonly-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		ApplicationReadonlyPart,
	],
	templateUrl: './application-readonly-flyout.part.html',
	styleUrl: './application-readonly-flyout.part.scss'
})
export class ApplicationReadonlyFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	public labels = getLabels(this.applicationService.urlService);


	close() {
		this.activeOffcanvas.close();
	}
}
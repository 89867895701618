import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		AcceleratorApplication: computed(() => en() ? `Accelerator Application` : `{{ ES: Accelerator Application }}`),
		Applicant: computed(() => en() ? `Applicant` : `{{ ES: Applicant }}`),
		Application: computed(() => en() ? `Application` : `{{ ES: Application }}`),
		ApplicationNotYetSubmitted: computed(() => en() ? `Application not yet submitted` : `{{ ES: Application not yet submitted }}`),
		ApplicationQuestions: computed(() => en() ? `Application Questions` : `{{ ES: Application Questions }}`),
		Business: computed(() => en() ? `Business` : `{{ ES: Business }}`),
		Complete: computed(() => en() ? `Complete` : `{{ ES: Complete }}`),
		CongratulationsOnApplyingMessage: computed(() => en() ? `Congratulations on taking a big step forward for your business: applying to an EforAll {programName} program. It is important that you provide clear and detailed information about your business idea so your application can be properly evaluated.\n\nResponses are required for all questions unless specified as optional. Your responses will be saved automatically so you do not need to complete your application in one session. Once you have finished, simply hit Submit.\n\nIf you have any questions, please direct them to[{siteEmail}](mailto: {siteEmail}).` : `{{ ES: Congratulations on taking a big step forward for your business: applying to an EforAll {programName} program. It is important that you provide clear and detailed information about your business idea so your application can be properly evaluated.\n\nResponses are required for all questions unless specified as optional. Your responses will be saved automatically so you do not need to complete your application in one session. Once you have finished, simply hit Submit.\n\nIf you have any questions, please direct them to[{siteEmail}](mailto: {siteEmail}). }}`),
		Deadline: computed(() => en() ? `Deadline` : `{{ ES: Deadline }}`),
		Incomplete: computed(() => en() ? `Incomplete` : `{{ ES: Incomplete }}`),
		InfoTextNoApplication: computed(() => en() ? `Application does not exists for the user. TBD text` : `{{ ES: Application does not exists for the user. TBD text }}`),
		Instructions: computed(() => en() ? `Instructions` : `{{ ES: Instructions }}`),
		InstructionsInfoMsg: computed(() => en() ? `Thank you for applying to our accelerator program. To ensure your application has the best chance of being accepted, please include detailed information about your business idea. Your application will be reviewed by a group of volunteer readers who do not know your business. Be sure to be as clear and detailed as possible.` : `{{ ES: Thank you for applying to our accelerator program. To ensure your application has the best chance of being accepted, please include detailed information about your business idea. Your application will be reviewed by a group of volunteer readers who do not know your business. Be sure to be as clear and detailed as possible. }}`),
		Language: computed(() => en() ? `Language` : `{{ ES: Language }}`),
		MakeChanges: computed(() => en() ? `Make Changes` : `{{ ES: Make Changes }}`),
		NoAccess: computed(() => en() ? `Not authorized` : `{{ ES: Not authorized }}`),
		NotSubmitted: computed(() => en() ? `Not Submitted` : `{{ ES: Not Submitted }}`),
		OfStepsComplete: computed(() => en() ? `of 4 steps complete` : `{{ ES: of 4 steps complete }}`),
		Pending: computed(() => en() ? `Pending` : `{{ ES: Pending }}`),
		PitchContestApplication: computed(() => en() ? `Pitch Contest Application` : `{{ ES: Pitch Contest Application }}`),
		Question: computed(() => en() ? `Question` : `{{ ES: Question }}`),
		Submitted: computed(() => en() ? `Submitted` : `{{ ES: Submitted }}`),
		TellUsAboutYou: computed(() => en() ? `Tell us about You` : `{{ ES: Tell us about You }}`),
		TellUsAboutYourBusiness: computed(() => en() ? `Tell us about Your Business` : `{{ ES: Tell us about Your Business }}`),
		TellUsAboutYourTeam: computed(() => en() ? `Tell us about Your Team` : `{{ ES: Tell us about Your Team }}`),
		ViewPendingApplication: computed(() => en() ? `View Pending Application` : `{{ ES: View Pending Application }}`),
		Withdraw: computed(() => en() ? `Withdraw` : `{{ ES: Withdraw }}`),
	}
}
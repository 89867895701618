import { UrlService } from '@eforall/common';
import { computed } from '@angular/core';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		
		Code: computed(() => en() ? `Code` : `{{ ES: Code }}`),
		EnterCode: computed(() => en() ? `Enter Code` : `{{ ES: Enter Code }}`),
		InvalidPattern: computed(() => en() ? `Code must match xxx-xxx-xxx pattern` : `{{ ES: Code must match xxx-xxx-xxx pattern }}`),
		Join: computed(() => en() ? `Join` : `{{ ES: Join }}`),
		JoinApplication: computed(() => en() ? `Join an Application` : `{{ ES: Join an Application }}`),
		MinLength: computed(() => en() ? `Code must be at least 12 letters long` : `{{ ES: Code must be at least 12 letters long }}`),
		MissingRequired: computed(() => en() ? `Required field` : `{{ ES: Required field }}`),
		NotValid: computed(() => en() ? `The code "{{pendingCode}}" is Invalid` : `{{ ES: The code "{{pendingCode}}" is Invalid }}`),
	};
}
import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ApplicationChanges: computed(() => en() ? `Application Changes` : `{{ ES: Application Changes }}`),
		Reopen: computed(() => en() ? `Reopen` : `{{ ES: Reopen }}`),
		Withdraw: computed(() => en() ? `Withdraw` : `{{ ES: Withdraw }}`),
		ChangeMessage: computed(() => en() ? `TODO: Message will be added soon.\n\n
			Talk about changing the status to pending for edits, must re-submit still.
			Also explain Withdraw and provide an option to do so.` : `{{ ES: TODO: Message will be added soon.\n\n
			Talk about changing the status to pending for edits, must re-submit still.
			Also explain Withdraw and provide an option to do so. }}`),
	}
}
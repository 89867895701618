@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" [text]="labels.Application()" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<application-readonly-part></application-readonly-part>
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}
<mobile-page-part>

	<div class="info-content">
		@if(!applicationService.application()?.isCoParticipant)
		{
		<h3>{{form.labels.Question()}} {{questionIndex() + 1}} {{form.labels.Of()}} {{applicationService.answers().length}}</h3>
		}
	</div>

	@if(!applicationService.application()?.isCoParticipant) {
	<div class="question-answer-content">
		<h4>
			{{applicationService.selectedQuestion()?.question?.text}}
		</h4>
		<common-text-field [field]="form.question()"></common-text-field>
	</div>

	<common-button-row [center]="true">
		@if(questionIndex() > 0)
		{
		<button class="btn btn-standard"
			(click)="applicationService.setSelectedQuestionAndNavigate(questionIndex()-1);"><i
				class="fa-solid fa-chevron-left"></i></button>
		}

		@if(questionIndex() >= 0 && questionIndex() + 1 !== applicationService.answers().length)
		{
		<button class="btn btn-primary btn-large" [disabled]="nextStepDisabled()"
			(click)="applicationService.setSelectedQuestionAndNavigate(questionIndex()+1);">
			{{form.labels.NextQuestion()}}
		</button>
		}

		@if(questionIndex() + 1 === applicationService.answers().length)
		{
		<button class="btn btn-primary btn-large" (click)="applicationService.navigateToApplication()">{{form.labels.button.Close()}}</button>
		}

	</common-button-row>
	}
	@else {
	<common-message alertType="warning">{{form.labels.NoAccessMessage()}}</common-message>
	}

</mobile-page-part>
import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		DemographicsInfo: computed(() => en() ? 'We compile this information **in bulk** and use it to demonstrate to our funders the people with whom we engage. **Please** consider telling us about yourself. We **promise** to never share your individual demographic information.\n\nWe believe entrepreneurship is for **all**.' : `{{ ES: We compile this information **in bulk** and use it to demonstrate to our funders the people with whom we engage. **Please** consider telling us about yourself. We **promise** to never share your individual demographic information.\n\nWe believe entrepreneurship is for **all**. }}`),

	};
}
import { Injectable, computed, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonDateTimeField, CommonDateTimeFieldConfig, CommonGeneralFieldConfig, CommonMultiSelectField, CommonNumberField, CommonNumberFieldConfig, CommonOptionsFieldConfig, CommonPhoneField, CommonSelectField, CommonTextField, CommonTextFieldConfig, UrlService } from "@eforall/common";
import { AppService, FlyoutService, FuncService } from '../../../../services';
import { getLabels } from './join-code.page.labels';

@Injectable({ providedIn: 'root' })
export class ApplicationJoinCodeFormService {

	private readonly func = inject(FuncService);
	public readonly urlService = inject(UrlService);
	private readonly app = inject(AppService);
	private readonly router = inject(Router);
	private flyoutService = inject(FlyoutService);
	private labels = getLabels(this.urlService);

	private values = signal<{
		title: string,
		address: AddressData | undefined,
		employmentStatusId: number,
		headOfHousehold: string,
		householdIncome: number | undefined,
		doingItFullTime: string,
	}>({
		title: '',
		address: undefined,
		employmentStatusId: 0,
		headOfHousehold: '',
		householdIncome: undefined,
		doingItFullTime: ''
	});

	private readonly doingItFullTimeOptions = computed(() => [
		{ value: 'Y', text: this.labels.IWorkFullTime() },
		{ value: 'N', text: this.labels.IWorkPartTime() }
	]);

	public readonly doingItFullTime = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.Involvement(), options: this.doingItFullTimeOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().doingItFullTime),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, doingItFullTime: value });
		},
		error: signal(''),
	}));



	readonly title = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.YourTitle(), min: 3, max: 50, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().title),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, title: value });
		},
		error: signal(''),
	}));

	readonly firstName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.form.FirstName(), min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.firstName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setFirstName({ firstName: value });
			this.app.setUserData({ ...user, firstName: value });
		},
		error: signal(''),
	}));

	readonly middleInit = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.form.MiddleInitial(), min: 0, max: 1, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.middleInit || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setMiddleInit({ middleInit: value });
			this.app.setUserData({ ...user, middleInit: value });
		},
		error: signal(''),
	}));

	readonly lastName = computed<CommonTextField>(() => ({
		config: signal<CommonTextFieldConfig>({ label: this.labels.form.LastName(), min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.lastName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setLastName({ lastName: value });
			this.app.setUserData({ ...user, lastName: value });
		},
		error: signal(''),
	}));


	readonly birthday = computed<CommonDateTimeField>(() => ({
		config: signal<CommonDateTimeFieldConfig>({ label: this.labels.form.Birthday(), required: true, isSpanish: this.urlService.isSpanish(), type: 'birthday', includeTime: false }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return user.birthDay && user.birthYear ? new Date(
				user.birthYear,				// year e.g. 1900
				Math.floor(user.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				user.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
		}),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {

			const user = this.app.data().user;
			// if (data.user.birthDay !== value) {
			// 	data.user.birthday = value;
			// 	this.app.setUserData(data.user);

			// 	await this.func.profile.form.setGenderOther({ birthday: value });
			// }
		},
		error: signal(''),
	}));



	private readonly gendersOptions = computed(() => [
		{ value: 'F', text: this.labels.genderOption.Female() },
		{ value: 'M', text: this.labels.genderOption.Male() },
		{ value: 'N', text: this.labels.genderOption.NonBinary() },
		{ value: 'O', text: this.labels.genderOption.Other() },
	]);

	readonly gender = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({
			label: this.labels.form.Gender(), options: this.gendersOptions(), required: false, isSpanish: this.urlService.isSpanish()
		}),
		actualValue: computed(() => this.app.data().user.gender || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const user = this.app.data().user;
			await this.func.profile.form.setGender({ gender: value });
			this.app.setUserData({ ...user, gender: value });

		},
		error: signal(''),
	}));


	readonly genderOther = computed<CommonTextField>(() => ({
		config: signal({ label: this.labels.form.OtherGender(), min: 0, max: 45, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.genderOther || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const user = this.app.data().user;
			if (user.gender == 'O') {

				await this.func.profile.form.setGenderOther({ genderOther: value });
				this.app.setUserData({ ...user, genderOther: value });
			}

		},
		error: signal(''),
	}));

	private readonly racesOptions = computed(() => [
		{ value: 'N', text: this.labels.raceOptions.AmericanIndian() },
		{ value: 'A', text: this.labels.raceOptions.Asian() },
		{ value: 'B', text: this.labels.raceOptions.AfricanAmerican() },
		{ value: 'P', text: this.labels.raceOptions.PacificIslander() },
		{ value: 'W', text: this.labels.raceOptions.White() },
	]);

	readonly races = computed<CommonMultiSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.Race(), options: this.racesOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.races || []),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {
			const user = this.app.data().user;
			await this.func.profile.form.setRaces({ races: value });
			this.app.setUserData({ ...user, races: value });
		},
		error: signal(''),
	}));


	private readonly hispanicOptions = computed(() => [
		{ value: 'Y', text: this.labels.hispanicOptions.IAmHispanic() },
		{ value: 'N', text: this.labels.hispanicOptions.IAmNotHispanic() }
	]);
	readonly hispanicOrLatino = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.HispanicOrLatino(), options: this.hispanicOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.hispanic || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setHispanic({ hispanic: pendingValue });
			this.app.setUserData({ ...user, hispanic: pendingValue });
		},
		error: signal(''),
	}));


	private readonly veteranOptions = computed(() => [
		{ value: 'Y', text: this.labels.veteranOptions.IAmVeteran() },
		{ value: 'N', text: this.labels.veteranOptions.IAmNotVeteran() }
	]);
	readonly veteran = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.Veteran(), options: this.veteranOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.veteran || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setVeteran({ veteran: pendingValue });
			this.app.setUserData({ ...user, veteran: pendingValue });
		},
		error: signal(''),
	}));


	private readonly immigrantOptions = computed(() => [
		{ value: 'Y', text: this.labels.immigrantOptions.Immigrant() },
		{ value: 'N', text: this.labels.immigrantOptions.NotImmigrant() }
	]);
	readonly immigrant= computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.Immigrant(), options: this.immigrantOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.immigrant || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setImmigrant({ immigrant: pendingValue });
			this.app.setUserData({ ...user, immigrant: pendingValue });
		},
		error: signal(''),
	}));



	private readonly nativeEnglishOptions = computed(() => [
		{ value: 'Y', text: this.labels.nativeEnglishOptions.NativeSpeaker() },
		{ value: 'N', text: this.labels.nativeEnglishOptions.NotNativeSpeaker() }
	]);
	readonly nativeEnglish = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.NativeEnglishSpeaker(), options: this.nativeEnglishOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeEnglish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeEnglish({ nativeEnglish: pendingValue });
			this.app.setUserData({ ...user, nativeEnglish: pendingValue });
		},
		error: signal(''),
	}));


	private readonly nativeSpanishOptions = computed(() => [
		{ value: 'Y', text: this.labels.nativeSpanishOptions.NativeSpeaker() },
		{ value: 'N', text: this.labels.nativeSpanishOptions.NotNativeSpeaker() }
	]);
	readonly nativeSpanish = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.NativeSpanishSpeaker(), options: this.nativeSpanishOptions(), required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeSpanish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeSpanish({ nativeSpanish: pendingValue });
			this.app.setUserData({ ...user, nativeSpanish: pendingValue });
		},
		error: signal(''),
	}));


	private readonly employmentStatusOptions = computed(() => [
		{ value: 1, text: this.labels.employmentStatusOptions.Unemployed() },
		{ value: 2, text: this.labels.employmentStatusOptions.Underemployed() },
		{ value: 3, text: this.labels.employmentStatusOptions.Employed() },
		{ value: 4, text: this.labels.employmentStatusOptions.Retired() },
	]);

	readonly employmentStatus = computed<CommonSelectField<number>>(() => ({
		config: signal<CommonOptionsFieldConfig<number>>({ label: this.labels.form.EmployementStatus(), options: this.employmentStatusOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().employmentStatusId),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			const values = this.values();
			this.values.set({ ...values, employmentStatusId: value });
		},

		error: signal(''),
	}));

	private readonly headOfHouseholdOptions = computed(() => [
		{ value: 'Y', text: this.labels.headOfHouseholdOptions.IAmHead() },
		{ value: 'N', text: this.labels.headOfHouseholdOptions.IAmNotHead() }
	]);

	readonly headOfHousehold  = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.form.HeadOfHousehold(), options: this.headOfHouseholdOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().headOfHousehold),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, headOfHousehold: value });
		},


		error: signal(''),
	}));



	readonly householdIncome = computed<CommonNumberField>(() => ({
		config: signal<CommonNumberFieldConfig>({ label: this.labels.form.HouseholdIncomeLastYear(), max: 100000000, required: true, isSpanish: this.urlService.isSpanish(), type: 'dollar', }),
		actualValue: computed(() => this.values().householdIncome),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			const values = this.values();
			this.values.set({ ...values, householdIncome: value });
		},

		error: signal(''),
	}));



	readonly phone  = computed<CommonPhoneField>(() => ({
		config: signal<CommonGeneralFieldConfig>({ label: this.labels.form.PhoneNumber(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.phone || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setPhone({ phone: value });
			this.app.setUserData({ ...user, phone: value });
		},
		error: signal(''),
	}));


	readonly address = computed<CommonAddressField>(() => ({
		config: signal<CommonAddressFieldConfig>({ label: this.labels.form.HomeAddress(), isSpanish: false, required: true }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return { inUS: user.inUS, latitude: user.placeLatitude, longitude: user.placeLongitude, placeAddress: user.placeAddress, placeId: user.placeId, streetAddress: user.address, zipId: user.zipId, };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			if (value && value.zipId) {
				const user = this.app.data().user;
				await this.func.profile.form.setAddress({ address: value });
				this.app.setUserData({
					...user,
					inUS: value.inUS,
					zipId: value.zipId,
					address: value.streetAddress,
					placeAddress: value.placeAddress,
					placeId: value.placeId,
					placeLatitude: value.latitude,
					placeLongitude: value.longitude,
				});
			}
		},
		error: signal(''),
	}));




	public canJoin = computed<boolean>(() => {
		const user = this.app.data().user;

		return !!this.doingItFullTime().actualValue()
			&& this.employmentStatus().actualValue() != 0
			&& !!this.headOfHousehold().actualValue()
			&& this.householdIncome != undefined
			&& !!this.title().actualValue()
			&& !!user.firstName
			&& !!user.lastName
			&& !!user.birthDay && !!user.birthYear
			&& !!user.gender
			&& !!user.races?.length
			&& !!user.hispanic
			&& !!user.immigrant
			&& !!user.veteran
			&& !!user.nativeEnglish
			&& !!user.nativeSpanish
			&& !!user.phone
			&& !!user.zipId;
	});



	public async joinApplication(code: string) {
		const canJoin = this.canJoin();
		if (!canJoin) {
			this.flyoutService.showMarkdownMessage(this.labels.CompleteAllFieldsMsg(), this.labels.JoinApplication());
		}
		else {

			const participantDetails: {
				title: string,
				address: AddressData,
				employmentStatusId: number,
				headOfHousehold: string,
				householdIncome: number,
				doingItFullTime: string,
			} = {
				title: this.title().actualValue(),
				address: this.address().actualValue()!,
				employmentStatusId: this.employmentStatus().actualValue(),
				headOfHousehold: this.headOfHousehold().actualValue(),
				householdIncome: this.householdIncome().actualValue()!,
				doingItFullTime: this.doingItFullTime().actualValue(),
			}
			const applicationId = await this.func.participant.addByCode({ code: code.split('-').join(''), participantDetails });

			if (applicationId) this.router.navigate(['applications', applicationId]);
		}

	}



	public openInstructionFlyout() {
		this.flyoutService.showMarkdownMessage(this.labels.InstructionsMsg(), this.labels.Instructions());
	}

}
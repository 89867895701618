<mobile-page-part>

	<div class="info-content">
		<h3>{{util.date.formatUTC(currentUTC, 'DOW MMM D, YYYY', 'No Time', 'en-US')}}
		</h3>
	</div>

	<!-- Prospect demo 1 content -->
	<!-- <div>
		<div class="d-flex flex-column gap-2">
			<div class="white-box">
				<h4>Application Under Review</h4>
				<div>Your application to the Merrimack Valley, MA 2025 Winter Accelerator program has been submitted and is under review as of Oct 21st, 2024. Please check back soon for updates from our staff.</div>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="/applications">Review Application</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>Finish your 2025 Winter Accelerator Application</h4>
				<div>You currently have an application to the Merrimack Valley, MA 2025 Winter Accelerator program that
					is still pending submission. Please complete and submit it before the deadline in 2 days.</div>
				<br>
				<i>Deadline: Oct 21st, 2024</i>
				<common-button-column>
					<button class="btn btn-primary btn-large" routerLink="/applications">Finish and Submit</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>Find a Pitch Contest near you!</h4>
				<div>Fast-paced, fun community events that help early-stage entrepreneurs gain valuable exposure and
					feedback on a business
					idea. Great opportunity to network, validate a business idea, and compete to win a cash prize. It is
					free for
					entrepreneurs to apply and free for all to attend.</div>
				<br>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="/programs">Learn More</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>New Feedback available!</h4>
				<div>Review the latest feedback from the Merrimack Valley, MA 2025 Winter Acceleartor.
					<br>
					<i>You have 3 new feedback records from Interviewers.</i>
				</div>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="/programs">View Feedback</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>New agreements to Sign</h4>
				<div>There are 2 new or updated agreements for you to sign:
					<br>
					<i>
						<ul>
							<li>Entrepreneur Code of Conduct </li>
							<li>Media Consent Agreement</li>
						</ul>
					</i>
				</div>
				<common-button-column>
					<button class="btn btn-primary btn-large" routerLink="/programs">Review and Sign</button>
				</common-button-column>
			</div>
		</div>
	</div> -->

	<!-- Cohort demo 2 content -->
	<div>
		<div class="d-flex flex-column gap-2">
			<div class="white-box">
				<h4>Upcoming 2024 Fall Accelerator Session</h4>
				<div>There is an upcoming Accelerator session on October 17, 2024. This session will cover the Branding
					and Messaging topic.</div>
				<br>
				<i>Topic Materials are available now!</i>
				<common-button-column>
					<button class="btn btn-primary btn-large" routerLink="calendar">Open Calendar</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>You have an incomplete class survey</h4>
				<div>Complete your class feedback survey from October 15, 2024 on Exploring Pricing Strategies!</div>
				<br>
				<i>Deadline: October 20th, 2024</i>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="surveys">Take Survey!</button>
				</common-button-column>
			</div>
		</div>
	</div>

	<!-- Cohort demo 3 content -->
	<!-- <div>
		<div class="d-flex flex-column gap-2">
			<div class="white-box">
				<h4>Upcoming 2024 Fall Accelerator Session</h4>
				<div>There is an upcoming Accelerator session on October 17, 2024. This session will cover the Branding
					and
					Messaging topic.</div>
				<br>
				<i>Topic Materials are available now!</i>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="calendar">Open Calendar</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>You have an incomplete class survey</h4>
				<div>Complete your class feedback survey from October 15, 2024 on Exploring Pricing Strategies!</div>
				<br>
				<i>Deadline: October 20th, 2024</i>
				<common-button-column>
					<button class="btn btn-standard btn-large" routerLink="surveys">Take Survey!</button>
				</common-button-column>
			</div>

			<div class="white-box">
				<h4>Accelerator Course: Section 2 - Customer Segments</h4>
				<div>Complete all activites associated with Section 2 of the EforAl Accelerator Course. You have completed 6 of 8 activites!</div>
				<br>
				<i>Due: October 17th, 2024</i>
				<common-button-column>
					<button class="btn btn-primary btn-large" routerLink="learning">Resume Course</button>
				</common-button-column>
			</div>
		</div>
	</div> -->

</mobile-page-part>
import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,
		...urlService.commonLabels,

		AddNewBusiness: computed(() => en() ? `Add a new business` : `{{ ES: Add a new business }}`),
		AlreadyAppliedForBy: computed(() => en() ? `Already applied for by` : `{{ ES: Already applied for by }}`),
		BusinessName: computed(() => en() ? `Business Name` : `{{ ES: Business Name }}`),
		BusinessType: computed(() => en() ? `Business Type` : `{{ ES: Business Type }}`),
		CreateApplication: computed(() => en() ? `Create Application` : `{{ ES: Create Application }}`),
		Industry: computed(() => en() ? `Industry` : `{{ ES: Industry }}`),
		WhichBusiness: computed(() => en() ? `Which business?` : `{{ ES: Which business? }}`),
	}
}
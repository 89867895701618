import { Injectable, computed, inject, signal } from "@angular/core";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonOptionsFieldConfig, CommonSelectField, UrlService } from "@eforall/common";
import { AppService, FuncService } from '../../../services';
import { getLabels } from "./apply.part.labels";

@Injectable({ providedIn: 'root' })
export class ApplicationApplyFormService {

	private readonly urlService = inject(UrlService);
	private readonly func = inject(FuncService);
	private readonly app = inject(AppService);
	private readonly labels = getLabels(this.urlService);


	readonly homeAddress = computed<CommonAddressField>(() => ({
		config: signal<CommonAddressFieldConfig>({ label:this.labels.form.HomeAddress(), isSpanish: this.urlService.isSpanish(), required: true }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return { inUS: user.inUS, latitude: user.placeLatitude, longitude: user.placeLongitude, placeAddress: user.placeAddress, placeId: user.placeId, streetAddress: user.address, zipId: user.zipId, };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			const user = this.app.data().user;
			if (value) {

				user.inUS = value.inUS;
				user.zipId = value.zipId;
				user.address = value.streetAddress;
				user.placeAddress = value.placeAddress;
				user.placeId = value.placeId;
				user.placeLatitude = value.latitude;
				user.placeLongitude = value.longitude;

				await this.func.profile.form.setAddress({ address: value });

				this.app.setUserData({ ...user });

			}
			this.homeAddress().saving.set(false);
		},
		error: signal(''),
	}));


	private values = signal<{ location: string, address: AddressData | undefined }>({ location: '', address: undefined });

	private readonly businessLocationOptions = [
		{ value: 'N', text: this.labels.UndecidedBusinessLocation() },
		{ value: 'Y', text: this.labels.IKnowMyBusinessLocation() },
	];

	public readonly businessLocation  = computed<CommonSelectField<string>>(() => ({
		config: signal<CommonOptionsFieldConfig<string>>({ label: this.labels.BusinessLocation(), options: this.businessLocationOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().location),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, location: value });
		},
		error: signal(''),
	}));




	readonly businessAddress = computed<CommonAddressField>(() => ({
		config: signal<CommonAddressFieldConfig>({ label: this.labels.BusinessZIPCode(), isSpanish: false, required: true }),
		actualValue: computed(() => this.values().address),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {
			const values = this.values();
			this.values.set({ ...values, address: value });
		},
		error: signal(''),
	}));



	public resetFields() {
		this.values.set({ location: '', address: undefined });
	}


}
import { Component, computed, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonProgressBarWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { CourseActivity, CourseLevel, CourseVersion } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { getIconForTopicMaterialType } from '../get-icon-for-topic-material-type';
import { LearningCourseService } from './course.service';
import { getLabels } from './learning.page.labels';

interface Material {
	id: number,
	name: string,
	icon: string,
	url: string,
}

interface Card {
	header: string,
	infoMarkdown: string,
	percentComplete?: number,
	activityCompleteCountMsg?: string,
	dueDate?: string,
	buttonText: string,
	navigateUrl: string,
	overviewUrl?: string,
}

interface SessionTopic {
	index: number,
	topicName: string,
	topicGoals: string,
	hasMaterials: boolean,
	topicMaterials: Material[],
}

@Component({
	selector: 'learning-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonRowWidget,
		CommonProgressBarWidget,
		NgbAccordionModule,
		FormsModule,
		MobileListWidget,
		MarkdownModule
	],
	providers: [provideMarkdown()],
	templateUrl: './learning.page.html'
})
export class LearningPage implements OnInit {
	public courseService = inject(LearningCourseService);
	private frame = inject(MobileFrameService);
	private router = inject(Router);
	public util = inject(UtilityService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	constructor() {
		this.frame.setUrlMetadata({
			url: `/learning`,
			backUrl: `/`,
			pageName: computed(() => this.labels.Learning()),
			headerText: computed(() => this.labels.Learning())
		});
	}

	async ngOnInit() {
		await this.courseService.loadCourses();
	}

	public topics = computed(() => this.createSessionTopics());

	public courseCardAndList = computed(() => this.generateCourseCardsAndDueList());

	private createSessionTopics(): SessionTopic[] {
		const topics = this.courseService.topics();
		if (!topics) return [];

		return topics.map((topic, idx) => ({
			index: idx + 1,
			topicName: topic.longNameLabel,
			topicGoals: this.urlService.isSpanish() ? topic.goalsES : topic.goalsEN,
			hasMaterials: topic.topicMaterials.length > 0,
			topicMaterials: topic.topicMaterials
				.sort((a, b) => a.displayOrder - b.displayOrder)
				.map((material, i) => ({
					id: i + 1,
					icon: getIconForTopicMaterialType(material.topicMaterialTypeId),
					name: material.name,
					url: material.url
				}))
		}));
	}

	private generateCourseCardsAndDueList(): { cards: Card[]; dueList: MobileListWithSubItem[] } {
		const courses = this.courseService.courses();
		const cards: Card[] = [];
		const dueList: MobileListWithSubItem[] = [];

		if (!courses) return { cards, dueList };

		courses.forEach(course => {
			const { dueLevels, pendingActivity, anyActivityStarted } = this.findFirstPendingAndDueLevels(course);

			if (!anyActivityStarted) {
				cards.push({
					buttonText: 'Start Course',
					header: `${course.title}: Get Started!`,
					infoMarkdown: `Welcome to the ${course.title}\n\nIntroductory message from Program TBD`,
					navigateUrl: `courses/${course.courseVersionId}`,
				});
			} else if (pendingActivity) {
				cards.push(this.createPendingActivityCard(course, pendingActivity));
			}

			dueList.push(...this.createDueList(dueLevels, course));
		});

		return { cards, dueList };
	}

	private createPendingActivityCard(course: CourseVersion, pendingActivity: {
		level: CourseLevel | undefined,
		activity: CourseActivity | undefined,
		parents: string[],
		levelIndex: number,
		activityIndex: number,
	}): Card {
		const { percentComplete, activityCompleteCountMsg } = this.calculateCompletion(pendingActivity.level);

		return {
			buttonText: 'Resume',
			header: `${course.title}: Pick up where you left off!`,
			infoMarkdown: pendingActivity.parents.join('\n\n'),
			percentComplete,
			activityCompleteCountMsg,
			dueDate: pendingActivity.level?.deadline
				? this.util.date.formatUTC(pendingActivity.level.deadline, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.isSpanish() ? 'es-US' : 'en-US')
				: undefined,
			navigateUrl: `courses/${course.courseVersionId}/activity/${pendingActivity.activity?.courseActivityStructureId}`,
			overviewUrl: `courses/${course.courseVersionId}`,
		};
	}

	private calculateCompletion(level?: CourseLevel): { percentComplete: number | undefined, activityCompleteCountMsg: string | undefined } {
		if (!level) return { activityCompleteCountMsg: undefined, percentComplete: undefined };

		const requiredActivities = level.activities.filter(activity => !activity.activityOptional);
		const completedActivities = requiredActivities.filter(activity => activity.response?.completedUTC).length;

		const percentComplete = requiredActivities.length > 0 ? Math.round((completedActivities / requiredActivities.length) * 100) : 0;
		return {
			percentComplete,
			activityCompleteCountMsg: requiredActivities.length > 0 ? `${completedActivities} of ${requiredActivities.length} Required Activities Completed (${percentComplete}%)` : undefined,
	}
}

	private createDueList(dueLevels: {
	level: CourseLevel,
	parents: string[],
	levelIndex: number,
	dueStatus: "Due Soon" | "Past Due",
}[], course: CourseVersion): MobileListWithSubItem[] {
	return dueLevels.map(level => ({
		icon: level.dueStatus === 'Past Due' ? 'fa-circle color-darkred' : 'fa-circle color-lightgray',
		iconStyle: 'fa-solid',
		text: level.parents.map(p => p.split(':')[0]).join(' > '),
		subText: level.level.deadline
			? 'Due: ' + this.util.date.formatUTC(level.level.deadline, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.isSpanish() ? 'es-US' : 'en-US')
			: '',
		callback: () => {
			this.router.navigate([this.courseService.urlService.withCurrentLanguage(`learning/courses/${course.courseVersionId}/level/${level.level.courseLevelStructureId}`)]);
		}
	}));
}


	private findFirstPendingAndDueLevels(courseVersion: CourseVersion): {
	pendingActivity: {
		level: CourseLevel | undefined,
			activity: CourseActivity | undefined,
				parents: string[],
					levelIndex: number,
						activityIndex: number,
		} | undefined,
		dueLevels: {
		level: CourseLevel,
			parents: string[],
				levelIndex: number,
					dueStatus: 'Due Soon' | 'Past Due'
	} [],
		anyActivityStarted: boolean
} {
	const now = Date.now() / 1000;
	const oneWeekLater = now + 7 * 24 * 60 * 60; // 1 week in milliseconds

	const checkDueStatus = (deadline?: number): 'Due Soon' | 'Past Due' | undefined => {
		if (!deadline) return undefined;
		if (deadline < now) return 'Past Due';
		if (deadline <= oneWeekLater) return 'Due Soon';
		return undefined;
	};

	let pendingActivity: {
		level: CourseLevel | undefined,
		activity: CourseActivity | undefined,
		parents: string[],
		levelIndex: number,
		activityIndex: number,
	} | undefined = undefined;

	const dueLevels: {
		level: CourseLevel,
		parents: string[],
		levelIndex: number,
		dueStatus: 'Due Soon' | 'Past Due'
	}[] = [];

	let anyActivityStarted = false;

	// Check pending activities directly in the course (not within levels)
	for (let i = 0; i < courseVersion.activities.length; i++) {
		const activity = courseVersion.activities[i]!;
		if (activity.response?.startUTC) {
			anyActivityStarted = true;
		}
		if (activity.response?.startUTC && !activity.response.completedUTC && !activity.activityOptional) {
			if (!pendingActivity) {
				pendingActivity = {
					level: undefined,
					activity,
					parents: [`Course: ${courseVersion.title}`],
					levelIndex: -1,
					activityIndex: i,
				};
			}
		}
	}

	// Helper function to recursively find due levels and pending activities in levels
	const findInLevel = (level: CourseLevel, currentParents: string[], levelIndex: number): void => {
		const dueStatus = checkDueStatus(level.deadline);

		for (let i = 0; i < level.activities.length; i++) {
			const activity = level.activities[i]!;
			if (activity.response?.startUTC) {
				anyActivityStarted = true;
			}
			if (activity.response?.startUTC && !activity.response.completedUTC && !activity.activityOptional) {
				if (!pendingActivity) {
					// Build the `parents` path with activity details if level type is not 'section'
					const parentsPath = [...currentParents];
					if (level.levelType !== 'section') {
						parentsPath.push(`Activity ${i + 1}: ${activity.activityTitle}`);
					}
					pendingActivity = {
						level,
						activity,
						parents: parentsPath,
						levelIndex,
						activityIndex: i,
					};
				}
			}
		}

		// Check if this level has overdue or due soon status
		if (dueStatus && !level.activities.every(act => act.response?.completedUTC || act.activityOptional)) {
			dueLevels.push({
				level,
				parents: [...currentParents],
				levelIndex,
				dueStatus
			});
		}

		// Recursively check child levels
		for (let i = 0; i < level.children.length; i++) {
			const childLevel = level.children[i]!;
			findInLevel(childLevel, [...currentParents, `${childLevel.levelType} ${i + 1}: ${childLevel.levelTitle}`], i + 1);
		}
	};

	// Loop through each top-level course version and its levels
	for (let i = 0; i < courseVersion.levels.length; i++) {
		const level = courseVersion.levels[i]!;
		findInLevel(level, [`${level.levelType} ${i + 1}: ${level.levelTitle}`], i + 1);
	}

	return {
		pendingActivity,
		dueLevels,
		anyActivityStarted
	};
}
}
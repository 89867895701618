<mobile-page-part>

	<div class="info-content">
		<h3>{{teamHeader()}}</h3>
	</div>


	@if(applicationService.application(); as application){
	@if(!application.isCoParticipant)
	{

	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'instructions',  'btn-standard': primaryBtn() !== 'instructions',}"
			(click)="openInstructions()">{{form.labels.Instructions()}}</button>
	</common-button-column>

	<div>
		<h4>
			{{form.labels.TeamSize()}}:
		</h4>
		<select class="form-select form-select-sm" [ngModel]="teamSize().value" (ngModelChange)="onChange($event)"
			[disabled]="disableTeamSize()">
			<option disabled value="">{{form.labels.form.SelectEllipses()}}</option>
			@for(option of teamSizeOptions; track option.value;)
			{
			<option [value]="option.value">{{option.text}}</option>
			}
		</select>
	</div>

	@if(teamSize().value === 'M'){



	<div class="question-answer-content">
		<h4>
			{{form.labels.MemberNameAndBio()}}
		</h4>
		<common-text-field [field]="form.restOfTeam()"></common-text-field>
	</div>


	<div>
		<h4>{{form.labels.TeamMemberParticipatingQuestion()}}</h4>
		<select class="form-select form-select-sm" [ngModel]="hasOrInviteParticipants()"
			(ngModelChange)="hasOrInviteParticipants.set($event)"
			[disabled]="applicationService.invitedParticipants().length > 0">
			<option disabled value="">{{form.labels.form.SelectEllipses()}}</option>
			<option value="Y">{{form.labels.button.Yes()}}</option>
			<option value="N">{{form.labels.button.No()}}</option>
		</select>
	</div>

	@if(hasOrInviteParticipants() === 'Y'){

	<mobile-list [items]="participantsList()" [header]="form.labels.InvitedParticipant()"></mobile-list>

	@if(!form.showInviteForm())
	{
	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'invite-participant',  'btn-standard': primaryBtn() !== 'invite-participant',}"
			(click)="form.showInviteForm.set(true)">{{form.labels.Invite()}}
			{{applicationService.invitedParticipants().length ? form.labels.Another():''}}
			{{form.labels.Participants()}}</button>
	</common-button-column>
	}

	@if(form.showInviteForm()){

	<div class="white-box d-flex flex-column gap-3">
		<h4 class="text-center">{{form.labels.ParticipantDetails()}}</h4>

		<common-text-field [field]="form.firstName()"></common-text-field>
		<common-text-field [field]="form.lastName()"></common-text-field>
		<common-text-field [field]="form.title()"></common-text-field>
		<common-email-field [field]="form.email()"></common-email-field>

		<common-button-row [center]="true">
			<button class="btn btn-primary" (click)="form.invite()">{{form.labels.Invite()}}</button>
			<button class="btn btn-standard" (click)="form.closeInvite()">{{form.labels.button.Cancel()}}</button>
		</common-button-row>

	</div>

	}
	}
	}

	<common-button-column>
		<button class="btn btn-large"
			[ngClass]="{ 'btn-primary': primaryBtn() === 'next-step',  'btn-standard': primaryBtn() !== 'next-step',}"
			(click)="applicationService.navigateTo({step:'questions', questionId: 0})" [disabled]="!showNextStep()">{{form.labels.NextStep()}}</button>
	</common-button-column>


	}
	@else if(application.isCoParticipant) {
	<common-message alertType="warning">{{form.labels.NoAccessMessage()}}</common-message>
	}
	}
</mobile-page-part>
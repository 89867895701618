<mobile-page-part [hideInfoBar]="true">

	<!-- <div class="d-flex flex-column gap-3"> -->

	<div class="white-box">
		{{labels.CanSignInWithAnyEmailMessage()}}<br>
		{{labels.CommunicationSentToPrimaryEmail()}}
	</div>

	<div>
		<h4>{{labels.PrimaryEmail()}}</h4>
		<div class="white-box text-center">{{emails().primary}}</div>
	</div>


	@if(emails().others.length)
	{
	<div>
		<h4>{{labels.OtherEmails()}}</h4>
		<div class="white-box d-flex flex-column gap-2">
			@for(email of emails().others; track email.emailId;){
			<div class="d-flex align-items-center gap-2">
				<div>{{email.email}}</div>
				<button class="btn btn-primary align-self-end ms-auto" (click)="setPrimaryEmail(email.emailId)">{{labels.MakePrimary()}}</button>
				<button class="btn btn-standard align-self-end" (click)="removeEmail(email.emailId)">
					<i class='fa-regular fa-xmark'></i>
				</button>
			</div>
			}
		</div>
	</div>
	}
	<div>

		<h4 class="align-self-start">{{labels.AddEmail()}}</h4>
		<div class="white-box">
			{{labels.AddNewEmailMsg()}}
		</div>
	</div>


	<!-- </div> -->
</mobile-page-part>
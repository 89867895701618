import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		AboutYou: computed(() => en() ? `About You` : `{{ ES: About You }}`),
	}
}
<mobile-page-part [hideInfoBar]="true">

	<markdown class="white-box" [data]="form.labels.DemographicsInfo()"></markdown>

	<div class="d-flex flex-column gap-2">

		<!-- Gender -->
		<common-select-field [field]="form.gender()"></common-select-field>
		@if(showOtherGender()){
		<common-text-field [field]="form.genderOther()"></common-text-field>
		}



		<!-- Hispanic or Latino -->
		<common-select-field [field]="form.hispanicOrLatino()"></common-select-field>


		<!-- Races -->
		<common-multiselect-field [field]="form.races()"></common-multiselect-field>


		<!-- Birthday -->
		<common-date-time-field [field]="form.birthday()"></common-date-time-field>

		<!-- Veteran -->
		<common-select-field [field]="form.veteran()"></common-select-field>



		<!-- Immigrant -->
		<common-select-field [field]="form.immigrant()"></common-select-field>


		<!-- Native English Speaker -->
		<common-select-field [field]="form.nativeEnglish()"></common-select-field>



		<!-- Native Spanish Speaker -->
		<common-select-field [field]="form.nativeSpanish()"></common-select-field>

	</div>

</mobile-page-part>
import { UrlService } from '@eforall/common';
import { computed } from '@angular/core';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,
		...urlService.commonLabels.button,

		Applications: computed(() => en() ? `Applications` : `{{ ES: Applications }}`),
		Completed: computed(() => en() ? `Completed` : `{{ ES: Completed }}`),
		GotInviteCode: computed(() => en() ? `Got an invite code?` : `{{ ES: Got an invite code? }}`),
		JoinApplication: computed(() => en() ? `Join an Application` : `{{ ES: Join an Application }}`),
		Open: computed(() => en() ? `Open` : `{{ ES: Open }}`),
		Pending: computed(() => en() ? `Pending` : `{{ ES: Pending }}`),
		PendingApplication: computed(() => en() ? `Pending Application` : `{{ ES: Pending Application }}`),
		PendingApplications: computed(() => en() ? `Pending Applications` : `{{ ES: Pending Applications }}`),
		PriorApplication: computed(() => en() ? `Prior Application` : `{{ ES: Prior Application }}`),
		PriorApplications: computed(() => en() ? `Prior Applications` : `{{ ES: Prior Applications }}`),
		NoApplications: computed(() => en() ? `No Applications` : `{{ ES: No Applications }}`),

	};
}
import { Component, computed, inject } from '@angular/core';
import { UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { getLabels } from './social-links.page.labels';

@Component({
	selector: 'profile-social-links-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './social-links.page.html'
})

export class ProfileSocialLinksPage {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	public labels = getLabels(this.urlService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/social-links',
			backUrl: '/profile',
			pageName: computed(() => this.labels.SocialLinks()),
			headerText: computed(() => this.labels.SocialLinks()),
		});
	}

}
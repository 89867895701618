import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ApplicationQuestions: computed(() => en() ? `Application Questions` : `{{ ES: Application Questions }}`),
		BusinessAddress: computed(() => en() ? `Business Address` : `{{ ES: Business Address }}`),
		BusinessName: computed(() => en() ? `Business Name` : `{{ ES: Business Name }}`),
		BusinessType: computed(() => en() ? `Business Type` : `{{ ES: Business Type }}`),
		Deadline: computed(() => en() ? `Deadline` : `{{ ES: Deadline }}`),
		Language: computed(() => en() ? `Language` : `{{ ES: Language }}`),
		Contact: computed(() => en() ? `Contact` : `{{ ES: Contact }}`),
		Complete: computed(() => en() ? `Complete` : `{{ ES: Complete }}`),
		Industry: computed(() => en() ? `Industry` : `{{ ES: Industry }}`),
		Offering: computed(() => en() ? `Offering` : `{{ ES: Offering }}`),
		AnnualRevenue: computed(() => en() ? `Annual Revenue (last year)` : `{{ ES: Annual Revenue (last year) }}`),
		NumFullTimeEmployees: computed(() => en() ? `Number of Full-Time Employees (include owners)` : `{{ ES: Number of Full-Time Employees (include owners) }}`),
		NumPartTimeEmployees: computed(() => en() ? `Number of Part-Time Employees (include owners)` : `{{ ES: Number of Part-Time Employees (include owners) }}`),
		NumContractors: computed(() => en() ? `Number of Contractors (IRS form 1099)` : `{{ ES: Number of Contractors (IRS form 1099) }}`),
		BusinessPhone: computed(() => en() ? `Business Phone` : `{{ ES: Business Phone }}`),
		Incomplete: computed(() => en() ? `Incomplete` : `{{ ES: Incomplete }}`),
		IWorkBusinessFullTime: computed(() => en() ? `I work on this business full-time` : `{{ ES: I work on this business full-time }}`),
		IWorkBusinessPartTime: computed(() => en() ? `I work on this business part-time` : `{{ ES: I work on this business part-time }}`),
		Participants: computed(() => en() ? `Participants` : `{{ ES: Participants }}`),
		FeedbackAvailable: computed(() => en() ? `Feedback Available` : `{{ ES: Feedback Available }}`),
		MakeChanges: computed(() => en() ? `Make Changes` : `{{ ES: Make Changes }}`),
		TellUsAboutYourBusiness: computed(() => en() ? `Tell us about Your Business` : `{{ ES: Tell us about Your Business }}`),
		TellUsAboutYou: computed(() => en() ? `Tell us about You` : `{{ ES: Tell us about You }}`),
		TellUsAboutYourTeam: computed(() => en() ? `Tell us about Your Team` : `{{ ES: Tell us about Your Team }}`),
		TeamNameAndBio: computed(() => en() ? `List name and a short bio of each member of your core team, whether they will participate or not.` : `{{ ES: List name and a short bio of each member of your core team, whether they will participate or not. }}`),
	}
}
@if(applyService.continueClicked())
{

<div class="d-flex flex-column gap-3">

	<table class="table table-md table-bordered align-middle mb-0">
		<tr>
			<td class="p-1">{{labels.form.HomeAddress()}}</td>
			<td class="p-1">{{applyService.form.homeAddress().actualValue()!.placeAddress}}</td>
		</tr>
		@if(applyService.form.businessLocation().actualValue() === 'Y' &&
		applyService.form.businessAddress().actualValue()?.placeAddress)
		{
		<tr>
			<td class="p-1">{{labels.BusinessAddress()}}</td>
			<td class="p-1">{{applyService.form.businessAddress().actualValue()?.placeAddress}}</td>
		</tr>
		}
	</table>

	@for(message of applyService.eligiblePrograms().messageLabels; track $index;){
	@if(messageLabels.messages[message]){
	<common-message alertType='warning'>
		<markdown [data]="messageLabels.messages[message]!()"></markdown>
	</common-message>
	}
	}


	<!-- Show pending Applications -->
	@if(applyService.pendingAppls().length)
	{
	<div class="d-flex flex-column gap-2">
		<h4>{{labels.MyPendingApplications()}}</h4>
		@for(appl of applyService.pendingAppls(); track appl.applicationId;)
		{
		<div class="white-box">
			<h4>{{appl.name}}</h4>
			<div>{{labels.Location()}}: {{appl.siteName}}</div>
			<div>{{labels.Deadline()}}: {{appl.deadline}}</div>
			<div>{{labels.Language()}}: {{appl.language}}</div>
			<div>{{labels.Contact()}}: <a target='_blank' href='mailto:{{appl.siteEmail}}'>{{appl.siteEmail}}</a></div>
			<common-button-column>
				<button class="btn btn-standard btn-large"
					[routerLink]="applyService.urlService.withCurrentLanguage('applications/'+appl.applicationId)">{{labels.button.Open()}}</button>
			</common-button-column>
		</div>
		}
	</div>
	}

	<!-- Show open application button -->
	@if(applyService.hasOpenApplications())
	{
	<common-button-row [center]="true">
		<button class="btn btn-standard btn-large"
			[routerLink]="applyService.urlService.withCurrentLanguage('applications')">{{labels.ViewOpenApplications()}}</button>
	</common-button-row>
	}



	<!-- Show eligible programs -->
	@if(applyService.eligiblePrograms().eligiblePrograms.length)
	{
	<div class="d-flex flex-column gap-2">
		<h4>{{labels.ProgramsInArea()}}</h4>
		@for(appl of applyService.eligiblePrograms().eligiblePrograms; track $index;)
		{
		<div class="white-box">
			<h4>{{appl.programInstance}} {{appl.programName}}</h4>
			<div>{{labels.Location()}}: {{appl.siteName}}</div>
			<div>{{labels.Deadline()}}: {{appl.applDeadline}}</div>
			@if(appl.accId){
			<div>{{labels.ClassStartDate()}}: {{appl.classStartDate}}</div>
			}
			<div>{{labels.Language()}}: {{appl.language}}</div>
			<div>{{labels.Contact()}}: <a target='_blank' href='mailto:{{appl.contactEmail}}'>{{appl.contactEmail}}</a></div>
			<common-button-column>
				<button class="btn btn-standard btn-large" (click)="applyService.applyForAProgram(appl)">
					@if(urlService.languageId() === appl.languageId) {
					{{labels.button.Apply()}}
					}
					@else {
					{{labels.button.ApplyInLanguage()}}

					}
				</button>
			</common-button-column>
		</div>
		}
	</div>
	}


</div>
}